import ButtonSecondary from "@/components/templateux/button/button-secondary";
import RotaryLogo from "@/components/templateux/rotary-logo";
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import { config } from "@/lib/config";
import { openWindow } from "@/lib/utils/openWindow";
import { Fragment } from "react";

function BroadcastSingleMini({
  display_data,
  getWeekday,
  getNormalTime,
  all_folks,
  userdata,
  openModal,
}) {
  const rotaryicon = (
    <>
      <RotaryLogo
        classSize="w-7 h-7 mx-auto "
        classAnimate=" animate-spin-slow"
        bgColor="rgba(220,38,38,0)"
        centerColor="rgba(220,38,38,1)"
        pointerColor="#111827"
        holeColor="rgb(220,38,38)"
        dialerColor="#ffffff"
        size="64"
      />
    </>
  );

  return (
    <>
      <div
        className=" text-left
        cursor-pointer group
        bg-gray-900 hover:bg-gray-800 
        "
      >
        <div className="flex items-center content-center">
          <div
            className="flex-1 px-4 
          "
          >
            <div
              className=""
              onClick={() => openModal(display_data?.broadcast_id, "form")}
            >
              <div className=" flex items-center content-center py-4">
                <div className="flex-1 ml-2">
                  <div className="flex items-center content-center  ">
                    <div className="flex-1">
                      <div className="text-white ">
                        <div className="">
                          <span className="font-bold">
                            {display_data?.broadcast_title}
                          </span>
                        </div>
                        <div className="italic text-xs sm:text-sm">
                          <span>featuring {display_data?.user_name}</span>
                          {all_folks.map((e, index) => (
                            <Fragment key={index}>
                              {index < all_folks.length - 1 && (
                                <span className="">, {e.user_name}</span>
                              )}

                              {index == all_folks.length - 1 && (
                                <span className="ml-1">
                                  &amp; {e.user_name}
                                </span>
                              )}
                            </Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-0">
                  <SvgJsx
                    type={`outline`}
                    icon={`chevron-right`}
                    className={` w-8 h-8 flex-0 ml-1 my-auto  text-gray-700 group-hover:text-white`}
                    title={`More info`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex-0 pr-4">
            <ButtonSecondary
              classColor={`group bg-red-600 hover:bg-red-500 text-white`}
              classSize={`text-xl px-4 py-2 `}
              onClick={() => {
                if (typeof window !== undefined) {
                  openWindow(
                    `${config.broadcast.app_base_url}?broadcast=${display_data?.broadcast_id}`,
                    config.broadcast.title,
                    window,
                    config.broadcast.width,
                    config.broadcast.height
                  );
                }
              }}
              message={rotaryicon}
              hasIcon={false}
              icon={null}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BroadcastSingleMini;
