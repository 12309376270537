import { useSession } from "next-auth/client";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { hasData } from "@/lib/utils/hasData";
import _ from "underscore";
import BroadcastSingleMini from "./broadcast-single-mini";
import { bumber } from "@/lib/utils/bumber";
import toast, { toastConfig } from "react-simple-toasts";
import { PlayerContext } from "@/components/context/player-context";
import { getExtention } from "@/lib/utils/getExtention";
import BroadcastSingleRow from "./broadcast-single-row";
import BroadcastSinglePost from "./broadcast-single-post";
import { getTimestamp } from "@/lib/utils/getTimestamp";
import { ifNull } from "@/lib/utils/ifNull";
import { getWeekday } from "@/lib/utils/getWeekday";

export default function BroadcastSingle({
  itemdata,
  hostsjsondata,
  hostsjsondataisLoading,
  hostsjsondataisError,
  placeholderdata,
  userdata,
  owner_id,
  owner_name,
  placeholder,
  display_view,
  openModal,
  closeModal,
  cache,
  set_cache,
  navtype,
  helptype,
  timestamp,
  post_override = false,
  permaurl,
  post_id,
  show_username,
}) {
  const [session, loading] = useSession();

  const { player_state, set_player_state } = useContext(PlayerContext);
  const [broadcast_date, set_broadcast_date] = useState(
    new Date(player_state?.start_date)
  );
  const [display_data, set_display_data] = useState(
    placeholder ? placeholderdata : itemdata
  );

  useEffect(() => {
    if (!placeholder && itemdata) {
      set_display_data(itemdata);
      set_broadcast_date(new Date(itemdata?.start_date));
    }
  }, [itemdata, placeholder]);

  const [isPlaying, set_isPlaying] = useState(
    player_state?.content_table?.toString() ==
      display_data?.content_table?.toString() &&
      player_state?.content_id?.toString() ==
        display_data?.content_id?.toString()
  );
  const [isShowSeek, set_isShowSeek] = useState(false);

  useEffect(() => {
    let this_status =
      player_state?.content_table?.toString() ==
        display_data?.content_table?.toString() &&
      player_state?.content_id?.toString() ==
        display_data?.content_id?.toString();

    set_isPlaying(this_status);
    set_isShowSeek(this_status);
  }, [
    player_state?.content_id,
    player_state?.content_table,
    display_data?.content_id,
    display_data?.content_table,
  ]);

  const getNormalTime = (time, include_meridian) => {
    if (!hasData(time)) return "";

    let timearr = time?.split(":");
    var hours = timearr[0];
    hours = Number(hours);
    var minutes = timearr[1] == "00" ? "" : `:${timearr[1]}`;

    var meridian = hours < 12 ? "am" : "pm";
    if (meridian == "pm") hours = hours - 12;
    if (hours == 0) hours = 12;

    if (include_meridian) {
      return `${hours}${minutes}${meridian}`;
    } else {
      return `${hours}${minutes}`;
    }
  };

  const doSeek = (seekto, x) => {
    player_state.playerRef.current.seekTo(seekto, "seconds");
    set_player_state({
      ...player_state,
      seek: seekto,
      seek_ratio: x / 100,
      seek_slider: x,
      isEnded: seekto >= player_state.duration,
    });
    return;
  };

  const doSeekbyRatio = (x) => {
    const seekto = Math.round((x / 100) * player_state?.duration);
    doSeek(seekto, x);

    return;
  };

  const doSeekbySeconds = (seconds) => {
    const ratio = (seconds * 100) / player_state?.duration;

    doSeek(seconds, ratio);

    return;
  };

  //FILTER OUT HOST DATA by local host json array
  const [filtered_hosts, set_filtered_hosts] = useState([]);

  useEffect(() => {
    if (
      !placeholder &&
      itemdata &&
      !hostsjsondataisLoading &&
      !hostsjsondataisError &&
      hostsjsondata
    ) {
      let temp_array = [];
      //DOES THIS SHOW HAVE GUEST HOSTS?
      if (itemdata?.bol_guests_json == "1") {
        //parse the cohost json
        let localhosts = JSON.parse(itemdata?.guests_json);

        //map through it to extract just the id
        localhosts?.map((f) => {
          if (hasData(f?.id?.toString())) temp_array.push(f?.id?.toString());
        });
      }
      //add the host to the array
      temp_array.unshift(`${itemdata?.user_id?.toString()}`);

      //USE THE FILTER LIST ABOVE TO REMOVE OTHER SCHEDULE INFO
      set_filtered_hosts(
        hostsjsondata?.filter((e) => temp_array.indexOf(e?.id?.toString()) > -1)
      );
    }
    return () => {};
  }, [
    itemdata,
    placeholder,
    hostsjsondata,
    hostsjsondataisLoading,
    hostsjsondataisError,
  ]);

  // console.log(hostsjsondata)

  const all_folks = filtered_hosts.filter((elem) =>
    _.where(JSON.parse(itemdata?.guests_json), { type: "guest" }).find(
      ({ id }) => elem.id === id
    )
  );

  const [toggle, set_toggle] = useState(display_data?.bol_public);
  useEffect(() => {
    set_toggle(display_data?.bol_public);
  }, [display_data?.bol_public]);

  const togglePublish = async (id) => {
    let vtoggle = bumber(toggle);
    set_toggle(vtoggle);

    toastConfig({
      time: 5000,
      className: "",
      position: "right",
    });

    if (hasData(id)) {
      const res = await fetch(
        `/api/private/broadcast/update-public?id=${id}&action=${vtoggle}`
      );
      const json = await res.json();
      if (json) {
        // console.log(json)
        const { action } = json[0][0];
        //console.log(action)
        if (action == 1) {
          toast(`Broadcast published`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
          //mutateItemsDeleteOne(id)
        } else {
          toast(`Broadcast unpublished`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
          //mutateItemsDeleteOne(id)
        }
      }
    }
  };

  const playerControls = async () => {
    //player is active
    if (
      player_state?.content_table?.toString() ==
        display_data?.content_table?.toString() &&
      player_state?.content_id?.toString() ==
        display_data?.content_id?.toString()
    ) {
      //have we reached the end of the file?
      if (player_state?.isEnded) {
        //yes, so we restart it
        set_player_state({
          ...player_state,
          isPlaying: !player_state?.isPlaying,
          isEnded: false,
          seek: 0,
          seek_slider: 0,
          seek_ratio: 0,
          isHide: "0",
        });

        //yes, so we restart it
        // set_player_state({...player_state
        //   ,file:`${display_data?.broadcast_url}${display_data?.recording_hash}${display_data?.content_table?.toString() == 'broadcast' ? `.${display_data?.extention}` : getExtention(display_data?.content_id)}`
        //   ,seek:0
        //   ,seek_ratio: 0
        //   ,seek_slider:0
        //   ,content_id: hasData(display_data?.content_id)
        //                     ? display_data?.content_id
        //                     : display_data?.post_id
        //   ,content_table: hasData(display_data?.content_table)
        //                     ? display_data?.content_table
        //                     : 'post'
        //   ,user_id: display_data?.user_id
        //   ,user_name: display_data?.user_name
        //   ,player_type: 'file'
        //   ,title: display_data?.broadcast_title
        //   ,duration: secondsBetween(display_data?.start_date,display_data?.end_date)
        //   ,stream_start_date: display_data?.start_date
        //   ,start_date: display_data?.start_date
        //   ,isPlaying:true
        //   ,isMuted:false
        //   ,isEnded:false
        //   ,avatar_url: display_data?.avatar_url
        // })
      } else {
        //not yet, so we just toggle play/pause

        set_player_state({
          ...player_state,
          isPlaying: !player_state?.isPlaying,
          isMuted: false,
          isHide: "0",
        });
      }
    } else {
      let duration = Math.ceil(display_data?.duration); //secondsBetween(display_data?.start_date,display_data?.end_date);

      let player_object = {
        ...player_state,
        file: `${display_data?.broadcast_url}${
          post_override ? display_data?.hash : display_data?.recording_hash
        }${
          post_override
            ? `.${display_data?.extention}`
            : getExtention(display_data?.content_id)
        }`,
        seek: getTimestamp(timestamp) !== 0 ? timestamp : 0,
        seek_ratio:
          getTimestamp(timestamp) !== 0 ? (timestamp * 100) / duration : 0,
        seek_slider:
          getTimestamp(timestamp) !== 0 ? (timestamp * 100) / duration : 0,
        content_id: hasData(display_data?.content_id)
          ? display_data?.content_id
          : display_data?.post_id,
        content_table: hasData(display_data?.content_table)
          ? display_data?.content_table
          : "post",
        user_id: display_data?.user_id,
        user_name: display_data?.user_name,
        player_type: "file",
        title: display_data?.broadcast_title,
        duration: duration,
        stream_start_date: display_data?.start_date,
        start_date: display_data?.start_date,
        isPlaying: true,
        isMuted: false,
        isEnded: false,
        avatar_url: display_data?.avatar_url,
        isHide: "0",
        post_id: ifNull(post_id, 0),
        post_type: itemdata?.post_type,
      };

      await set_player_state(player_object);
    }
  };

  // useEffect(()=>{
  //   if (hasTimestamp(timestamp) !== 0 && player_state?.content_id > 0) {
  //     console.log("player_state",player_state)
  //     doSeekbySeconds(timestamp)
  //   }
  // },[player_state?.content_id,timestamp])

  // MINI VIEW
  if (display_view == "mini") {
    return (
      <>
        <BroadcastSingleMini
          display_data={display_data}
          getWeekday={getWeekday}
          getNormalTime={getNormalTime}
          all_folks={all_folks}
          userdata={userdata}
          openModal={openModal}
        />
      </>
    );
  }

  // POST VIEW
  if (display_view == "post") {
    return (
      <>
        <BroadcastSinglePost
          itemdata={itemdata}
          userdata={userdata}
          display_view={display_view}
          openModal={openModal}
          closeModal={closeModal}
          cache={cache}
          set_cache={set_cache}
          navtype={navtype}
          helptype={helptype}
          display_data={display_data}
          getWeekday={getWeekday}
          getNormalTime={getNormalTime}
          all_folks={all_folks}
          player_state={player_state}
          set_player_state={set_player_state}
          doSeekbyRatio={doSeekbyRatio}
          permaurl={permaurl}
          broadcast_date={broadcast_date}
          toggle={toggle}
          togglePublish={togglePublish}
          session={session}
          playerControls={playerControls}
          post_override={post_override}
          isPlaying={isPlaying}
          set_isPlaying={set_isPlaying}
          isShowSeek={isShowSeek}
          set_isShowSeek={set_isShowSeek}
          show_username={show_username}
        />
      </>
    );
  }

  // ROW VIEW

  return (
    <>
      <div className="mb-2">
        <BroadcastSingleRow
          itemdata={itemdata}
          userdata={userdata}
          display_view={display_view}
          openModal={openModal}
          closeModal={closeModal}
          cache={cache}
          set_cache={set_cache}
          navtype={navtype}
          helptype={helptype}
          display_data={display_data}
          getWeekday={getWeekday}
          getNormalTime={getNormalTime}
          all_folks={all_folks}
          player_state={player_state}
          set_player_state={set_player_state}
          doSeekbyRatio={doSeekbyRatio}
          permaurl={permaurl}
          broadcast_date={broadcast_date}
          toggle={toggle}
          togglePublish={togglePublish}
          session={session}
          playerControls={playerControls}
          post_override={post_override}
          isPlaying={isPlaying}
          set_isPlaying={set_isPlaying}
          isShowSeek={isShowSeek}
          set_isShowSeek={set_isShowSeek}
        />
      </div>
    </>
  );
}
