import SvgJsx from "@/components/templateux/svg/svg-jsx";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import PopupMenuOption from "@/components/templateux/popup-menu-option";
import { hasData } from "@/lib/utils/hasData";
import toast from "react-simple-toasts";
import { useRouter } from "next/router";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";

export default function BroadcastMenuOptions({
  item_id,
  item_name,
  shrink = false,
  userdata,
  owner_id,
  ssr_isadmin,
  helptype,
  timestamp,
  session,
  openModal,
  closeModal,
  cache,
  set_cache,
  stream_url,
  bol_status,
  bol_recording,
  permaurl,
  player_state,
}) {
  const [isMounted, set_isMounted] = useState(false);
  const { isConfirmed } = useConfirm();

  useEffect(() => {
    set_isMounted(true);
  }, []);

  const router = useRouter();

  const deleteHandler = async (item_id) => {
    if (!ssr_isadmin[helptype]) {
      toast(`You are not authorized to delete this, ya dingbat!`, {
        time: 1000,
        className: "",
        clickable: true,
        clickClosable: false,
      });
      return -2;
    }

    if (hasData(item_id)) {
      const confirmed = await isConfirmed(
        "Are you sure you want to delete this?"
      );
      // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
      if (confirmed) {
        const res = await fetch(
          `/api/private/broadcast/delete?id=${item_id}&del=1`,
          { method: "DELETE" }
        );
        const json = await res.json();

        if (json) {
          const { outcome } = json;
          if (outcome == 1) {
            toast(`Broadcast deleted`, {
              time: 1000,
              className: "",
              clickable: true,
              clickClosable: false,
            });

            //new cache to trigger updated swr call
            set_cache(+new Date());
          }
        }
      }
    }
  };

  const downloadHandler = async (url) => {
    if (typeof window !== "undefined") window.open(url);
  };

  const ref = useRef(null);
  //const openMenu = () => {if (ref.current) ref.current.open()};
  const closeMenu = () => {
    if (ref.current) ref.current.close();
  };
  //const toggleMenu = () => {if (ref.current) ref.current.toggle()};

  const PopupTrigger = (
    <div aria-haspopup="true" className="menu-item w-5 h-5 flex-0 ">
      <SvgJsx
        type="outline"
        icon="dots-v"
        className={`cursor-pointer  flex-0 mr-1 rounded-md w-5 h-5
                                          text-gray-400 hover:text-gray-200`}
        title="More"
      />
    </div>
  );

  return (
    <div className="menu flex items-center content-center  flex-0  ">
      {!isMounted && PopupTrigger}
      {isMounted && (
        <Popup
          trigger={PopupTrigger}
          position="left top"
          on="click"
          closeOnDocumentClick
          closeOnEscape
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          contentStyle={{
            padding: "0px",
            border: "none",
            backgroundColor: "transparent",
          }}
          arrow={false}
          nested
          ref={isMounted ? ref : null}
        >
          <div
            className="menu flex flex-col w-48 gap-y-1 rounded-md shadow-md p-2
                     bg-gray-800 "
          >
            <PopupMenuOption
              isDisplay={true}
              onClick={() => {
                closeMenu();
                router.push(permaurl);
              }}
              icon="link-sm"
              title="Permalink"
            />

            <PopupMenuOption
              isDisplay={true}
              onClick={() => {
                closeMenu();
                openModal(item_id, "info");
              }}
              icon="information-circle-sm"
              title="Broadcast Info"
            />

            {/* {player_state?.content_id?.toString() == item_id?.toString() &&
          <PopupMenuOption
            isDisplay={true}
            onClick={()=>{
              closeMenu();
              router.push(`${permaurl}?t=${player_state?.seek}`);
            }}
            icon="link-sm"
            title="TimeStamp"
          />
          } */}

            {/* EDIT */}
            {ssr_isadmin[helptype] && (
              <>
                {["1", "2"].indexOf(bol_recording?.toString()) > -1 &&
                  bol_status == "2" && (
                    <PopupMenuOption
                      isDisplay={true}
                      onClick={() => {
                        closeMenu();
                        downloadHandler(stream_url);
                      }}
                      icon="check-circle-sm"
                      title="Download"
                    />
                  )}

                <PopupMenuOption
                  isDisplay={true}
                  onClick={() => {
                    closeMenu();
                    openModal(item_id, "form");
                  }}
                  icon="pencil-sm"
                  title="Edit"
                />
                <PopupMenuOption
                  isDisplay={true}
                  onClick={() => {
                    closeMenu();
                    deleteHandler(item_id);
                  }}
                  icon="trash-sm"
                  title="Delete"
                />
              </>
            )}
          </div>
        </Popup>
      )}
    </div>
  );
}
